import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { calculatePercentage } from '../utils/calculatePercentage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 930,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 730,
  },
  cell: {
    border: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  cellHour: {
    border: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  leftBorder: {
    borderLeft: '1px solid',
  },
  headerCell: {
    borderBottom: 'none',
  },
  headerCellLast: {
    borderBottom: 'none',
  },
  headerBottomLine: {
    textWrap: 'nowrap',
    borderBottom: '1px solid',
  },
  headerTotalBeats: {
    borderBottom: '1px solid',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  cellSeparator: {
    borderLeft: '1px solid',
    borderBottom: '1px solid',
  },
  spacer: {
    height: theme.spacing(4),
  },
}));

type Beats = {
  [key: string]: number[];
};

type HistogramSummaryProps = {
  beats: Beats;
  ventricular: Beats;
  ventricularDuplets: Beats;
  ventricularTriplets: Beats;
  ventricularRuns: Beats;
  supraVentricular: Beats;
  supraVentricularDuplets: Beats;
  supraVentricularTriplets: Beats;
  supraVentricularRuns: Beats;
};

export function HistogramSummary({
  beats,
  ventricular,
  ventricularDuplets,
  ventricularTriplets,
  ventricularRuns,
  supraVentricular,
  supraVentricularDuplets,
  supraVentricularTriplets,
  supraVentricularRuns,
}: HistogramSummaryProps) {
  const classes = useStyles();

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Beats Summary
      </Typography>

      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerBottomLine} rowSpan={2} align="center">
              Time
            </TableCell>
            <TableCell className={classes.headerTotalBeats} rowSpan={2} align="center">
              Total Beats
            </TableCell>
            <TableCell className={classes.headerCell} colSpan={3} align="center">
              Heart Rate
            </TableCell>
            <TableCell className={classes.headerCell} colSpan={2} align="center">
              Bradycardia-Tachycardia %
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellSeparator}>Min</TableCell>
            <TableCell className={classes.headerBottomLine}>Avg</TableCell>
            <TableCell className={classes.headerBottomLine}>Max</TableCell>
            <TableCell className={classes.cellSeparator}>&lt;50 bpm</TableCell>
            <TableCell className={classes.headerBottomLine}>&gt;100 bpm</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(beats).map(([hourRange, values]) => {
            const beatsLength = values?.length;

            const filteredValues = values.filter((value) => value != null); // heart rate beats are averaged every 30s, so there may be null values
            const beatsMin = beatsLength ? Math.min(...filteredValues) : 0;

            const beatsAvg =
              Math.round(
                filteredValues.reduce((acc, val) => acc + val, 0) / filteredValues.length,
              ) || 0;

            const beatsMax = beatsLength ? Math.max(...filteredValues) : 0;

            const beatsLowerThan50 = filteredValues?.filter((value) => value < 50).length;
            const beatsMoreThan100 = filteredValues?.filter((value) => value > 100).length;
            const percentageLowerThan50 = calculatePercentage(beatsLowerThan50, filteredValues.length);
            const percentageMoreThan100 = calculatePercentage(beatsMoreThan100, filteredValues.length);

            return (
              <TableRow key={hourRange}>
                <TableCell className={classes.cellHour}>{hourRange}</TableCell>
                <TableCell className={classes.cell}>{beatsLength}</TableCell>
                <TableCell className={`${classes.cell} ${classes.leftBorder}`}>
                  {beatsMin}
                </TableCell>
                <TableCell className={classes.cell}>{beatsAvg}</TableCell>
                <TableCell className={classes.cell}>{beatsMax}</TableCell>
                <TableCell className={`${classes.cell} ${classes.leftBorder}`}>
                  {percentageLowerThan50}
                </TableCell>
                <TableCell className={classes.cell}>{percentageMoreThan100}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <div className={classes.spacer} />

      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerBottomLine} rowSpan={2} align="center">
              Time
            </TableCell>
            <TableCell className={classes.headerTotalBeats} rowSpan={2} align="center">
              Total Beats
            </TableCell>
            <TableCell className={classes.headerCell} colSpan={4} align="center">
              Ventricular
            </TableCell>
            <TableCell className={classes.headerCellLast} colSpan={4} align="center">
              Supraventricular
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellSeparator}>Beats</TableCell>
            <TableCell className={classes.headerBottomLine}>Dup</TableCell>
            <TableCell className={classes.headerBottomLine}>Trip</TableCell>
            <TableCell className={classes.headerBottomLine}>Run</TableCell>
            <TableCell className={classes.cellSeparator}>Beats</TableCell>
            <TableCell className={classes.headerBottomLine}>Dup</TableCell>
            <TableCell className={classes.headerBottomLine}>Trip</TableCell>
            <TableCell className={classes.headerBottomLine}>Run</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.entries(beats).map(([hourRange, values]) => {
            const beatsLength = values?.length;

            const ventricularBeats = ventricular[hourRange]?.length;
            const ventricularBeatsPercentage = calculatePercentage(ventricularBeats, beatsLength);
            const ventricularBeatText =
              ventricularBeats > 0 ? `${ventricularBeats} (${ventricularBeatsPercentage})` : '';

            const ventricularDup = ventricularDuplets[hourRange]?.length || '';
            const ventricularTrip = ventricularTriplets[hourRange]?.length || '';
            const ventricularRun = ventricularRuns[hourRange]?.length || '';

            const supraventricularBeats = supraVentricular[hourRange]?.length;
            const supraventricularBeatsPercentage = calculatePercentage(
              supraventricularBeats,
              beatsLength,
            );
            const supraventricularBeatText =
              supraventricularBeats > 0
                ? `${supraventricularBeats} (${supraventricularBeatsPercentage})`
                : '';

            const supraventricularDup = supraVentricularDuplets[hourRange]?.length || '';
            const supraventricularTrip = supraVentricularTriplets[hourRange]?.length || '';
            const supraventricularRun = supraVentricularRuns[hourRange]?.length || '';

            return (
              <TableRow key={hourRange}>
                <TableCell className={classes.cellHour}>{hourRange}</TableCell>
                <TableCell className={classes.cell}>{beatsLength}</TableCell>

                <TableCell className={`${classes.cell} ${classes.leftBorder}`}>
                  {ventricularBeatText}
                </TableCell>
                <TableCell className={classes.cell}>{ventricularDup}</TableCell>
                <TableCell className={classes.cell}>{ventricularTrip}</TableCell>
                <TableCell className={classes.cell}>{ventricularRun}</TableCell>
                <TableCell className={`${classes.cell} ${classes.leftBorder}`}>
                  {supraventricularBeatText}
                </TableCell>
                <TableCell className={classes.cell}>{supraventricularDup}</TableCell>
                <TableCell className={classes.cell}>{supraventricularTrip}</TableCell>
                <TableCell className={classes.cell}>{supraventricularRun}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
}
