import { useMemo } from 'react';
import UPlotReact from 'uplot-react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chartContainer: {
    marginBottom: theme.spacing(4),
  },
}));

interface QtcIntervalChartProps {
  annotationTimestamps: number[];
  qtcIntervals: (null | number)[];
}

const chunkDataInSixHours = (
  timestamps: number[],
  qtcIntervals: (null | number)[],
): { timestamps: number[]; qtcIntervals: (null | number)[] }[] => {
  const SIX_HOURS_IN_SECONDS = 6 * 60 * 60;
  const chunkedData: { timestamps: number[]; qtcIntervals: number[] }[] = [];
  let chunkStart = timestamps[0];
  let tempTimestamps: any[] = [];
  let tempRates: any[] = [];

  for (let i = 0; i < timestamps.length; i += 1) {
    if (timestamps[i] - chunkStart < SIX_HOURS_IN_SECONDS) {
      tempTimestamps.push(timestamps[i]);
      tempRates.push(qtcIntervals[i]);
    } else {
      chunkedData.push({ timestamps: tempTimestamps, qtcIntervals: tempRates });
      tempTimestamps = [timestamps[i]];
      tempRates = [qtcIntervals[i]];
      chunkStart = timestamps[i];
    }
  }

  // Add the last chunk if it's not empty
  if (tempTimestamps.length > 0) {
    chunkedData.push({ timestamps: tempTimestamps, qtcIntervals: tempRates });
  }

  return chunkedData;
};

export function QtcIntervalChart({ annotationTimestamps, qtcIntervals }: QtcIntervalChartProps) {
  const classes = useStyles();

  const chunks = useMemo(
    () => chunkDataInSixHours(annotationTimestamps, qtcIntervals),
    [annotationTimestamps, qtcIntervals],
  );

  return (
    <Container className={classes.root} component={Paper}>
      {chunks.map((chunk, index) => {
        const options = {
          title: index === 0 ? 'QTc Interval Chart' : '',
          width: 754,
          height: 220,

          axes: [
            {
              grid: {
                show: true,
                width: 1 / devicePixelRatio,
                stroke: '#e3e3e3',
              },
              ticks: {
                width: 1 / devicePixelRatio,
                stroke: '#e3e3e3',
              },
            },
            {
              label: 'QTc (sec)',
            },
          ],
          series: [
            {
              label: 'Time',
            },
            {
              label: 'QTc (sec)',
              width: 1 / devicePixelRatio,
              drawStyle: 0,
              lineInterpolation: null,
              stroke: 'red',
              spanGaps: true,
            },
          ],
          plugins: [],
          scales: { x: { time: true } },
        };

        return (
          <div key={`${index + 1}`} className={classes.chartContainer}>
            <UPlotReact
              options={options}
              data={[chunk.timestamps, chunk.qtcIntervals]}
            />
          </div>
        );
      })}
    </Container>
  );
}
