import { format, formatDuration, intervalToDuration } from 'date-fns';

const formatDistanceLocale = {
  xSeconds: '{{count}} sec',
  xMinutes: '{{count}} min',
  xHours: '{{count}} h',
};

const shortEnLocale = {
  formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count),
};

export function formatTimeDuration({
  durationInMilliseconds,
  showSeconds = false,
  detailed = false,
}: {
  durationInMilliseconds: number;
  showSeconds?: boolean;
  detailed?: boolean;
}) {
  const duration = intervalToDuration({ start: 0, end: durationInMilliseconds });

  const timeFormat = ['hours', 'minutes'];

  if (showSeconds) {
    timeFormat.push('seconds');
  }

  const formattedDuration = formatDuration(duration, {
    format: timeFormat,
    locale: shortEnLocale,
  });

  if (detailed) {
    return formattedDuration
      .replace('h', 'Hours')
      .replace('min', 'Minutes')
      .replace('sec', 'Seconds');
  }

  return formattedDuration;
}

export function formatDateTimeRange({
  fromMiliseconds,
  toMiliseconds,
  showSeconds = false,
  detailed = false,
}: {
  fromMiliseconds: number;
  toMiliseconds: number;
  showSeconds?: boolean;
  detailed?: boolean;
}) {
  const dayFormat = 'yyyy-MM-dd';
  const timeFormat = showSeconds ? 'HH:mm:ss' : 'HH:mm';
  const formatString = `${dayFormat} ${timeFormat}`;

  const startedFull = format(fromMiliseconds, formatString);
  const endedFull = format(toMiliseconds, formatString);

  if (detailed) {
    return `from ${startedFull} to ${endedFull}`;
  }

  const isSameDay = format(fromMiliseconds, 'yyyy-MM-dd') === format(toMiliseconds, 'yyyy-MM-dd');
  const dateRange = isSameDay
    ? `${startedFull} - ${format(toMiliseconds, timeFormat)}`
    : `${startedFull} to ${endedFull}`;

  return dateRange;
}
