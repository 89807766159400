import { User } from '@auth0/auth0-react';
import { differenceInYears, format } from 'date-fns';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { capitalize } from './utils/capitalize';
import { calculateBMIWithCategory } from './utils/calculateBMIWithCategory';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 400,
  },
  cell: {
    border: 'none',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

export function UserInfo({ user: currentUser }: { user: User }) {
  const classes = useStyles();

  const { name, surname, email, sex, dateOfBirth, weight, height } = currentUser;

  
  const age = differenceInYears(new Date(), new Date(currentUser.dateOfBirth));
  
  const bmi = calculateBMIWithCategory({ sex, age, weight, height });

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        User Information:
      </Typography>
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell}>Name</TableCell>
            <TableCell className={classes.cell} align="right">
              {capitalize(name) || 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Last name</TableCell>
            <TableCell className={classes.cell} align="right">
              {capitalize(surname) || 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Email</TableCell>
            <TableCell className={classes.cell} align="right">
              {email}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Sex</TableCell>
            <TableCell className={classes.cell} align="right">
              {capitalize(sex)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Date of birth</TableCell>
            <TableCell className={classes.cell} align="right">
              {`${format(new Date(dateOfBirth), 'yyy-MM-dd')} (${age})`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Weight</TableCell>
            <TableCell className={classes.cell} align="right">
              {weight} kg
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Height</TableCell>
            <TableCell className={classes.cell} align="right">
              {height} cm
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>BMI</TableCell>
            <TableCell className={classes.cell} align="right">
              {bmi}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
}
