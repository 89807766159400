export type Maybe<T> = T;
// export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: number;
  MongoObjectID: string;
};

export enum Sex {
  Male = 'MALE',
  Female = 'FEMALE',
  Diverse = 'DIVERSE',
}

export type Analysis = {
  __typename?: 'Analysis';
  id: Scalars['MongoObjectID'];
  user: User;
  recording: Recording;
  analysedAt: Scalars['Date'];
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  annotations: Array<Maybe<Annotation>>;
  humanAnnotations: Array<Maybe<Annotation>>;
  noises?: Maybe<Array<Maybe<IntervalAnnotation>>>;
  afibs?: Maybe<Array<Maybe<IntervalAnnotation>>>;
  rpeaksCount: Scalars['Int'];
  rPeaks: Array<Maybe<Scalars['Int']>>;
  qPeaks: Array<Maybe<Scalars['Int']>>;
  sPeaks: Array<Maybe<Scalars['Int']>>;
  tPeaks: Array<Maybe<Scalars['Int']>>;
  pPeaks: Array<Maybe<Scalars['Int']>>;
  automaticRpeakAnnotations?: Maybe<Array<Maybe<AutomaticRpeakAnnotation>>>;
  rate?: Maybe<Array<Maybe<Scalars['Int']>>>;
  avgHeartrate?: Maybe<Scalars['Int']>;
  beatCount?: Maybe<Scalars['Int']>;
  bradycardiaEpisodes?: Maybe<Array<Maybe<BradycardiaEpisode>>>;
  pauseEpisodes?: Maybe<Array<Maybe<PauseEpisode>>>;
  minHeartrate?: Maybe<Scalars['Int']>;
  maxHeartrate?: Maybe<Scalars['Int']>;
  ventricularDuplets?: Array<Maybe<VentricularDuplet>>;
  supraVentricularDuplets?: Array<Maybe<SupraVentricularDuplet>>;
  ventricularTriplets?: Array<Maybe<VentricularTriplet>>;
  supraVentricularTriplets?: Array<Maybe<SupraVentricularTriplet>>;
  ventricularRuns?: Array<Maybe<VentricularRun>>;
  supraVentricularRuns?: Array<Maybe<SupraVentricularRun>>;
  clusterData: ClusterData;
  sequenceIntervals: SequenceIntervals;
  extremesByHeartRate: ExtremesByHeartRate;
};

type ExtremesByHeartRate = {
  min: Scalars['Int']
  minIndex: Scalars['Int']
  minExcerpt: Scalars['Float']
  max: Scalars['Int']
  maxIndex: Scalars['Int']
  maxExcerpt: Scalars['Float']
}

type SequenceIntervals = {
  qpeaks: Array<Maybe<Scalars['Int']>>;
  ppeaks: Array<Maybe<Scalars['Int']>>;
  rpeaks: Array<Maybe<Scalars['Int']>>;
  speaks: Array<Maybe<Scalars['Int']>>;
  tpeaks: Array<Maybe<Scalars['Int']>>;
  stSegments: Array<Maybe<Scalars['Int']>>;
  qtcIntervals: Array<Maybe<Scalars['Int']>>;
  qtcIndexes: Array<Maybe<Scalars['Int']>>;
};

export type Cluster = {
  clusterNumber: Scalars['Int'];
  rpeakLocations: Array<Scalars['Int']>;
  excerpts: Array<Array<Maybe<Scalars['Float']>>>;
  isValid: Scalars['Boolean'];
};

export type AnnotationValueData = {
  annotationValue: Scalars['String'];
  clusters: Array<Cluster>;
};

export type ClusterData = {
  id: Scalars['MongoObjectID'];
  recordingId: Scalars['ID'];
  sequenceId: Scalars['ID'];
  analysisId: Scalars['ID'];
  annotationValues: Array<AnnotationValueData>;
};

export type VentricularDuplet = {
  __typename: string;
  id: Scalars['MongoObjectID'];
  sampleIndexes: Array<Maybe<Scalars['Int']>>;
  excerpt: RecordingJson;
  isValid: Scalars['Boolean'];
};
export type SupraVentricularDuplet = {
  __typename: string;
  id: Scalars['MongoObjectID'];
  sampleIndexes: Array<Maybe<Scalars['Int']>>;
  excerpt: RecordingJson;
  isValid: Scalars['Boolean'];
};
export type VentricularTriplet = {
  __typename: string;
  id: Scalars['MongoObjectID'];
  sampleIndexes: Array<Maybe<Scalars['Int']>>;
  excerpt: RecordingJson;
  isValid: Scalars['Boolean'];
};
export type SupraVentricularTriplet = {
  __typename: string;
  id: Scalars['MongoObjectID'];
  sampleIndexes: Array<Maybe<Scalars['Int']>>;
  excerpt: RecordingJson;
  isValid: Scalars['Boolean'];
};
export type VentricularRun = {
  __typename: string;
  id: Scalars['MongoObjectID'];
  sampleIndexes: Array<Maybe<Scalars['Int']>>;
  excerpt: RecordingJson;
  isValid: Scalars['Boolean'];
};
export type SupraVentricularRun = {
  __typename: string;
  id: Scalars['MongoObjectID'];
  sampleIndexes: Array<Maybe<Scalars['Int']>>;
  excerpt: RecordingJson;
  isValid: Scalars['Boolean'];
};

export type BradycardiaEpisode = {
  __typename?: 'BradycardiaEpisode';
  end?: Maybe<Scalars['Int']>;
  duration_s?: Maybe<Scalars['Int']>;
  mean_hr?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  excerpt: RecordingJson;
  isValid?: Maybe<Scalars['Boolean']>;
};

export type PauseEpisode = {
  __typename?: 'PauseEpisode';
  startIndex?: Maybe<Scalars['Int']>;
  endIndex?: Maybe<Scalars['Int']>;
  excerpt: RecordingJson;
  isValid?: Maybe<Scalars['Boolean']>;
};

export enum AnnotationValue {
  N = 'N',
  S = 'S',
  V = 'V',
  F = 'F',
  U = 'U',
  AFib = 'AFib',
}

export type Annotation = {
  __typename?: 'Annotation';
  sampleIndex: Scalars['Int'];
  analysis?: Analysis;
  annotationValue: AnnotationValue;
  metadata?: Maybe<Metadata>;
};

export type AutomaticRpeakAnnotation = {
  __typename?: 'AutomaticRpeakAnnotation';
  sampleIndex: Scalars['Int'];
  annotationValue: AnnotationValue;
  excerpt: RecordingJson;
};

export type IntervalAnnotation = {
  __typename?: 'IntervalAnnotation';
  id: Scalars['MongoObjectID'];
  recordingId: Scalars['ID'];
  startIndex: Scalars['Int'];
  endIndex: Scalars['Int'];
  annotationValue: AnnotationValue;
  isFromAnalysis?: Maybe<Scalars['Boolean']>;
  excerpt: RecordingJson;
  isValid: Scalars['Boolean'];
};

export type CreateIntervalInput = {
  recordingId: Scalars['MongoObjectID'];
  startIndex: Scalars['Int'];
  endIndex: Scalars['Int'];
};

export type EditIntervalInput = {
  annotationId: Scalars['MongoObjectID'];
  startIndex: Scalars['Int'];
  endIndex: Scalars['Int'];
};

export type RecordingJson = {
  __typename?: 'RecordingJson';
  ch1: Array<Maybe<Scalars['Float']>>;
  ch2?: Maybe<Array<Maybe<Scalars['Float']>>>;
  ch3?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type Recording = {
  __typename?: 'Recording';
  id: Scalars['MongoObjectID'];
  fileName?: Maybe<Scalars['String']>;
  recordingStartedAt?: Maybe<Scalars['Date']>;
  recordingEndedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  recordingIsUploaded: Scalars['Boolean'];
  user: User;
  analysis?: Maybe<Analysis>;
  downloadUrl?: Maybe<Scalars['String']>;
  json: RecordingJson;
  channelCount: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  metadata?: Maybe<Metadata>;
  ecgRecordingUploadUrl?: Maybe<Scalars['String']>;
  flags?: Maybe<Array<Maybe<RecordingFlag>>>;
  externalAnnotationMetadata?: Maybe<ExternalAnnotationMetadata>;
  pointComments?: Maybe<Array<Maybe<PointComment>>>;
  rpeaksCount?: Maybe<Scalars['Int']>;
};

export type Sequence = {
  id: Scalars['MongoObjectID'];
  recordingIds: Array<string>;
  analysisId?: string;
  userId: string;
  startedAt: Maybe<Scalars['Date']>;
  endedAt: Maybe<Scalars['Date']>;
  analysis?: Analysis;
  user?: User;
  status: SequenceStatus;
};

export enum SequenceStatus {
  Pending = 'pending',
  Analyzing = 'analyzing',
  analyzed = 'analyzed',
}

export type SequenceResponse = {
  success: boolean;
  error?: string;
  sequence?: Sequence;
};

export type SequencesResponse = {
  success: boolean;
  error?: string;
  sequences?: Array<Sequence>;
};

export type ExternalAnnotationMetadata = {
  __typename?: 'ExternalAnnotationMetadata';
  startedAt?: Maybe<Scalars['Date']>;
  completedAt?: Maybe<Scalars['Date']>;
  startedBy?: Maybe<Scalars['MongoObjectID']>;
  completedBy?: Maybe<Scalars['MongoObjectID']>;
};

export type PointComment = {
  __typename?: 'PointComment';
  id: Scalars['MongoObjectID'];
  recordingId: Scalars['MongoObjectID'];
  sampleIndex: Scalars['Int'];
  comment: Scalars['String'];
  metadata?: Maybe<Metadata>;
};

export type HealthIndication = {
  id: Scalars['MongoObjectID'];
  content: Scalars['String'];
  user: User;
  recordedAt: Scalars['Date'];
  type: HealthIndicationType;
  duration_s?: Maybe<Scalars['Int']>;
  excerpt: RecordingJson;
};

export enum HealthIndicationType {
  ACTIVITY = 'Activity',
  SYMPTOMS = 'Symptoms',
}

export type ExternalAnnotationMetadataInput = {
  startedAt?: Maybe<Scalars['Date']>;
  completedAt?: Maybe<Scalars['Date']>;
  startedBy?: Maybe<Scalars['MongoObjectID']>;
  completedBy?: Maybe<Scalars['MongoObjectID']>;
};

export enum RecordingFlag {
  FullyAnnotatedProfessionally = 'FULLY_ANNOTATED_PROFESSIONALLY',
  PseudoAnnotated = 'PSEUDO_ANNOTATED',
  AnnotatedByMl = 'ANNOTATED_BY_ML',
  Unchecked = 'UNCHECKED',
  BadQuality = 'BAD_QUALITY',
  ForExternalAnnotating = 'FOR_EXTERNAL_ANNOTATING',
  ExternallyAnnotated = 'EXTERNALLY_ANNOTATED',
  ForFutureReview = 'FOR_FUTURE_REVIEW',
  AiDataset = 'AI_DATASET',
}

export type RecordingInput = {
  comment?: Maybe<Scalars['String']>;
  flags?: Maybe<Array<Maybe<RecordingFlag>>>;
  externalAnnotationMetadata?: Maybe<ExternalAnnotationMetadataInput>;
};

export type Metadata = {
  __typename?: 'Metadata';
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['MongoObjectID'];
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  role: Role;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  height?: Scalars['Int'];
  weight?: Scalars['Int'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  dateOfSignup: Scalars['Date'];
  timezone: Maybe<Scalars['String']>;
  isImpersonated: Scalars['Boolean'];
  permissions: Array<Maybe<Scalars['String']>>;
};

export enum Role {
  Annotator = 'ANNOTATOR',
  User = 'USER',
  Doctor = 'DOCTOR',
  Clinic = 'CLINIC',
  Admin = 'ADMIN',
}

export type Patient = {
  __typename?: 'Patient';
  id: Scalars['MongoObjectID'];
  clinicId: Scalars['String'];
  name: Scalars['String'];
  surname?: Scalars['String'];
  email?: Scalars['String'];
  patientRefId?: Scalars['String'];
  sex?: Maybe<Sex>;
  height?: Scalars['Int'];
  weight?: Scalars['Int'];
  dateOfBirth?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  isActive: Scalars['Boolean'];
};

export type SharedToken = {
  id: Scalars['MongoObjectID'];
  token: Scalars['String'];
  userId: Scalars['String'];
  createdAt: Scalars['Date'];
  expiresAt: Scalars['Date'];
};

export type AnalysisStatistics = {
  __typename?: 'AnalysisStatistics';
  minHeartrate?: Maybe<Scalars['Float']>;
  maxHeartrate?: Maybe<Scalars['Float']>;
  avgHeartrate?: Maybe<Scalars['Float']>;
  beatCount?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  getMyRecordings: Array<Maybe<Recording>>;
  getMyRecording: Recording;
  getRecordingsById: Array<Maybe<Recording>>;
  getRecordingsForExternalAnnotating: Array<Maybe<Recording>>;
  getCurrentUser?: Maybe<User>;
  getUserList: GetUserListResponse;
  getAnalysisStatisticsByInterval?: Maybe<AnalysisStatistics>;
  getSequentialUserRecordings: SequentialUserRecordingsResponse;
  getSequenceById: SequenceResponse;
};

export type QueryGetMyRecordingArgs = {
  getMyRecordingId: Scalars['MongoObjectID'];
};

export type QueryGetRecordingsByIdArgs = {
  recordingIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryGetAnalysisStatisticsByIntervalArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type QueryGetSequentialUserRecordingsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateUser: GenericResponse;
  createMyRecording: CreateRecordingResponse;
  updateRecording: RecordingResponse;
  updateAnnotation: GenericResponse;
  deleteAnnotation: GenericResponse;
  createPointComment: PointCommentResponse;
  deletePointComment: PointCommentResponse;
  updatePointComment: PointCommentResponse;
  createNoise: NoiseResponse;
  deleteNoise: NoiseResponse;
  createAFib: AFibResponse;
  deleteAFib: AFibResponse;
  editInterval: NoiseResponse;
  analyzeRecording: GenericResponse;
  deleteRecording: RecordingResponse;
  downloadRecordingsBatch: GenericResponse;
  generateRecordingPdf: GenericResponse;
  analyzeSequence: (sequenceId: string) => Promise<GenericResponse>;
};

export type MutationUpdateUserArgs = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  dateOfBirth?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type MutationCreateMyRecordingArgs = {
  channelCount?: Maybe<Scalars['Int']>;
};

export type MutationUpdateRecordingArgs = {
  id?: Maybe<Scalars['MongoObjectID']>;
  input: RecordingInput;
};

export type MutationUpdateAnnotationArgs = {
  recordingId: Scalars['MongoObjectID'];
  sampleIndex: Scalars['Int'];
  value: AnnotationValue;
};

export type MutationDeleteAnnotationArgs = {
  recordingId: Scalars['MongoObjectID'];
  sampleIndex: Scalars['Int'];
};

export type MutationCreatePointCommentArgs = {
  input: CreatePointCommentInput;
};

export type MutationDeletePointCommentArgs = {
  id?: Maybe<Scalars['MongoObjectID']>;
};

export type MutationUpdatePointCommentArgs = {
  input: UpdatePointCommentInput;
};

export type MutationCreateNoiseArgs = {
  input: CreateIntervalInput;
};

export type MutationDeleteNoiseArgs = {
  id?: Maybe<Scalars['MongoObjectID']>;
};

export type MutationCreateAFibArgs = {
  input: CreateIntervalInput;
};

export type MutationDeleteAFibArgs = {
  id?: Maybe<Scalars['MongoObjectID']>;
};

export type MutationEditIntervalArgs = {
  input: EditIntervalInput;
};

export type MutationAnalyzeRecordingArgs = {
  recordingId: Scalars['MongoObjectID'];
};

export type MutationDeleteRecordingArgs = {
  recordingId: Scalars['MongoObjectID'];
};

export type MutationDownloadRecordingsBatchArgs = {
  recordingIds: Array<Maybe<Scalars['String']>>;
};

export type MutationGenerateRecordingPdfArgs = {
  recordingId: Scalars['MongoObjectID'];
};

export type RecordingsSequence = {
  __typename?: 'RecordingsSequence';
  recordings?: Maybe<Array<Maybe<Recording>>>;
};

export type SequentialUserRecordingsResponse = {
  __typename?: 'SequentialUserRecordingsResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  sequences?: Maybe<Array<Maybe<RecordingsSequence>>>;
};

export type GetUserListResponse = {
  __typename?: 'GetUserListResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type RecordingResponse = {
  __typename?: 'RecordingResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  recording?: Maybe<Recording>;
};

export type NoiseResponse = {
  __typename?: 'NoiseResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  noise: IntervalAnnotation;
};

export type AFibResponse = {
  __typename?: 'AFibResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  afib: IntervalAnnotation;
};

export type CreatePointCommentInput = {
  recordingId: Scalars['ID'];
  sampleIndex: Scalars['Int'];
  comment: Scalars['String'];
};

export type UpdatePointCommentInput = {
  pointCommentId: Scalars['ID'];
  comment: Scalars['String'];
};

export type PointCommentResponse = {
  __typename?: 'PointCommentResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  pointComment?: Maybe<PointComment>;
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type GenericResponseWithWarning = {
  __typename?: 'GenericResponseWithWarning';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
};

export type CreateRecordingResponse = {
  __typename?: 'CreateRecordingResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  /** @deprecated Use recording.id */
  recordingId?: Maybe<Scalars['MongoObjectID']>;
  /** @deprecated Use recording.ecgRecordingUploadUrl */
  ecgRecordingUploadUrl?: Maybe<Scalars['String']>;
  recording: Recording;
};
