import { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import { SequenceResponse, SequenceStatus, User } from 'types/schema.type';
import { HistogramSummary } from './histogram/HistogramSummary';
import { LoadingSpinner } from '../LoadingSpinner';
import { Warnings } from './Warnings';
import { UserInfo } from './UserInfo';
import { getSequenceAnalysisAnnotations } from './utils/getSequenceAnnotations';
import { getSequenceAnalysisEpisodes } from './utils/getSequenceEpisodes';
import { RateChart } from './charts/RateChart';
import { QtcIntervalChart } from './charts/QtcIntervalChart';
import { HistogramChart } from './histogram/HistogramChart';
import { getSequenceAnalysisAutomaticAnnotationsData } from './utils/getSequenceAutomaticAnnotationsData';
import { Summary } from './Summary';
import { ClusterChartList } from './cluster/ClusterChartList';
import { getSequenceBeatSummaryData } from './utils/getSequenceBeatSummaryData';
import { getSequenceEctopicBeats } from './utils/getSequenceEctopicBeats';
import { EctopyChartList, EctopyType } from './charts/EctopyChartList';
import { getSummaryData } from './utils/getSummaryData';
import { calculateAverageRate } from './utils/calculateAverageRate';
import { calculateAverageValues } from './utils/calculateAverageValues';
import { AFibTable, AFibChartList } from './afib';
import { BradycardiaTable, BradycardiaChartList } from './bradycardia';
import { PauseEpisodesTable, PauseEpisodeChartList } from './pause';
import { ExtremesHeartRateChartList } from './extremesHeartRate';
import { getUnixTime } from './utils/getUnixTime';
import { ANALYSIS_FRAGMENT, EPISODES_AND_AFIBS_FRAGMENT } from './fragments';
import { getSequenceTitle } from './utils/getSequenceTitle';

const GET_SEQUENCE_TABLE_DATA = gql`
  ${ANALYSIS_FRAGMENT}

  query GetSequenceByIdTableData($sequenceId: String!) {
    getSequenceById(sequenceId: $sequenceId) {
      error
      success
      sequence {
        id
        recordingIds
        analysisId
        userId
        status
        startedAt
        endedAt
        analysis {
          ...AnalysisFragment
          clusterData {
            id
            annotationValues {
              annotationValue
              clusters {
                clusterNumber
                rpeakLocations
                isValid
              }
            }
          }
        }
      }
    }
  }
`;

const GET_SEQUENCE_CHART_DATA = gql`
  ${ANALYSIS_FRAGMENT}
  ${EPISODES_AND_AFIBS_FRAGMENT}

  query GetSequenceByIdChartData($sequenceId: String!) {
    getSequenceById(sequenceId: $sequenceId) {
      error
      success
      sequence {
        id
        startedAt
        endedAt
        analysis {
          ...AnalysisFragment
          ...EpisodesAndAfibsFragment
          clusterData {
            id
            annotationValues {
              annotationValue
              clusters {
                clusterNumber
                rpeakLocations
                excerpts
                isValid
              }
            }
          }
          sequenceIntervals {
            qtcIntervals
            qtcIndexes
          }
          extremesByHeartRate {
            min
            minIndex
            minExcerpt
            max
            maxIndex
            maxExcerpt
          }
        }
      }
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      name
      surname
      dateOfBirth
      sex
      email
      weight
      height
    }
  }
`;

type GetSequenceTableDataResponse = {
  getSequenceById: SequenceResponse;
};

type GetSequenceChartDataResponse = {
  getSequenceById: SequenceResponse;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonStart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  buttonMark: {
    margin: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(12),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

export function SequenceListItem() {
  const classes = useStyles();
  const { sequenceId = '' } = useParams<{ sequenceId: string }>();

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery<{ getCurrentUser: User }>(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  });

  const {
    data: sequenceTableData,
    loading: sequenceTableDataLoading,
    error: sequenceTableDataError,
  } = useQuery<GetSequenceTableDataResponse, { sequenceId: string }>(GET_SEQUENCE_TABLE_DATA, {
    variables: {
      sequenceId,
    },
    errorPolicy: 'all',
  });

  const {
    data: sequenceChartData,
    loading: sequenceChartDataLoading,
    error: sequenceChartDataError,
  } = useQuery<GetSequenceChartDataResponse, { sequenceId: string }>(GET_SEQUENCE_CHART_DATA, {
    variables: {
      sequenceId,
    },
    errorPolicy: 'all',
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const isDownloadRequest = localStorage.getItem('isDownloadRequest') === 'true';

    if (isDownloadRequest) {
      const interval = setInterval(() => {
        const pdfContent = document.getElementById('pdf-ready');

        if (pdfContent) {
          window.print();
          clearInterval(interval);
          localStorage.removeItem('isDownloadRequest');
        }
      }, 1000); // Check every second

      return () => clearInterval(interval);
    }
  }, []);

  const isLoading = userLoading || sequenceTableDataLoading;
  const error = userError || sequenceTableDataError || sequenceChartDataError;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error)
    return (
      <div id="pdf-ready">
        <div>Error happened while loading data:</div>
        {error.graphQLErrors.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>{message}</span>
        ))}
      </div>
    );

  if (!userData?.getCurrentUser) return <div id="pdf-ready">User not signed in</div>;

  const sequenceTable = sequenceTableData?.getSequenceById.sequence;
  const sequenceChart = sequenceChartData?.getSequenceById.sequence;

  if (!sequenceTable || !sequenceTable?.analysis) {
    if (sequenceTable?.status === SequenceStatus.Analyzing) {
      return (
        <div id="pdf-ready">
          <p>ECG analysis in progress...</p>
          <p>Your report will be available once ready.</p>
        </div>
      );
    }

    return (
      <div id="pdf-ready">
        <p>Sequence has not been analyzed yet.</p>
        <p>You need to trigger sequence analysis manually by pressing analysis (last) button.</p>
      </div>
    );
  }

  const beatCount = sequenceTable.analysis.beatCount || 0;
  const sequenceAnnotationsTimestampsInSs = getSequenceAnalysisAnnotations(sequenceTable);

  const averageRates = calculateAverageRate(
    sequenceAnnotationsTimestampsInSs,
    sequenceTable.analysis.rate || [],
  );

  const qtcTimestamps = (sequenceChart?.analysis?.sequenceIntervals?.qtcIndexes || []).map((v) =>
    getUnixTime(sequenceTable.startedAt, v),
  );
  const averageQtcIntervals = calculateAverageValues(
    qtcTimestamps,
    sequenceChart?.analysis?.sequenceIntervals?.qtcIntervals || [],
  );

  const { afibs, bradycardiaEpisodes, pauseEpisodes } = getSequenceAnalysisEpisodes(
    sequenceChart || sequenceTable,
    averageRates,
  );

  const {
    ventricularDuplets,
    ventricularTriplets,
    ventricularRuns,
    supraVentricularDuplets,
    supraVentricularTriplets,
    supraVentricularRuns,
  } = getSequenceEctopicBeats(sequenceChart);

  const { clusterData } = getSequenceAnalysisAutomaticAnnotationsData(sequenceChart);

  const {
    ectopicBeats,
    beatsTableData,
    ventricularTableData,
    ventricularDupletsTableData,
    ventricularTripletsTableData,
    ventricularRunsTableData,
    supraventricularTableData,
    supraVentricularDupletsTableData,
    supraVentricularTripletsTableData,
    supraVentricularRunsTableData,
  } = getSequenceBeatSummaryData(sequenceTable, averageRates);

  const sequenceSummaryData = getSummaryData({
    beatCount,
    averageRates,
    ectopicBeats,
    averageQtcIntervals,
    pauseEpisodes,
  });

  const rpeakIndexes = sequenceTable.analysis.annotations.map(
    (annotation) => annotation.sampleIndex,
  );

  const duration = sequenceTable.endedAt - sequenceTable.startedAt;

  const filteredAverageRates = averageRates.filter((rate): rate is number => rate !== null);

  const { title, subtitle } = getSequenceTitle({
    from: sequenceTable.startedAt,
    to: sequenceTable.endedAt,
    seconds: duration / 1000,
  });

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {subtitle}
      </Typography>

      <UserInfo user={userData?.getCurrentUser} />
      <Summary summary={sequenceSummaryData} />

      <HistogramChart rate={filteredAverageRates} />
      <RateChart annotationTimestamps={sequenceAnnotationsTimestampsInSs} rate={averageRates} />

      <AFibTable afibs={afibs} />
      <AFibChartList episodes={afibs} rpeaks={rpeakIndexes} isLoading={sequenceChartDataLoading} />

      <BradycardiaTable bradycardiaEpisodes={bradycardiaEpisodes} sequenceDurationMs={duration} />
      <BradycardiaChartList
        episodes={bradycardiaEpisodes as any}
        rpeaks={rpeakIndexes}
        isLoading={sequenceChartDataLoading}
      />

      <PauseEpisodesTable pauseEpisodes={pauseEpisodes} />
      <PauseEpisodeChartList
        episodes={pauseEpisodes as any}
        rpeaks={rpeakIndexes}
        isLoading={sequenceChartDataLoading}
      />

      <HistogramSummary
        beats={beatsTableData}
        ventricular={ventricularTableData}
        ventricularDuplets={ventricularDupletsTableData}
        ventricularTriplets={ventricularTripletsTableData}
        ventricularRuns={ventricularRunsTableData}
        supraVentricular={supraventricularTableData}
        supraVentricularDuplets={supraVentricularDupletsTableData}
        supraVentricularTriplets={supraVentricularTripletsTableData}
        supraVentricularRuns={supraVentricularRunsTableData}
      />

      {/* Rest Charts */}
      {sequenceChartDataLoading ? (
        <LoadingSpinner />
      ) : (
        <div id="pdf-ready">
          <QtcIntervalChart
            qtcIntervals={averageQtcIntervals}
            annotationTimestamps={qtcTimestamps}
          />
          <ExtremesHeartRateChartList
            recordingStartedAt={sequenceTable.startedAt}
            extremes={sequenceChart?.analysis?.extremesByHeartRate as any}
            rpeaks={rpeakIndexes}
          />
          <EctopyChartList title={EctopyType.VentricularDuplet} beats={ventricularDuplets} />
          <EctopyChartList title={EctopyType.VentricularTriplet} beats={ventricularTriplets} />
          <EctopyChartList title={EctopyType.VentricularRun} beats={ventricularRuns} />
          <EctopyChartList
            title={EctopyType.SupraVentricularDuplet}
            beats={supraVentricularDuplets}
          />
          <EctopyChartList
            title={EctopyType.SupraVentricularTriplet}
            beats={supraVentricularTriplets}
          />
          <EctopyChartList title={EctopyType.SupraVentricularRun} beats={supraVentricularRuns} />
          <ClusterChartList annotations={clusterData} />
        </div>
      )}

      {/* Footer */}
      <Warnings />
    </div>
  );
}
