import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AutomaticAnnotationClusterData } from '../utils/getSequenceAutomaticAnnotationsData';
import { ExcerptMultipleChart } from '../charts/ExcerptMultipleChart';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  chart: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chartContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

// Define the type for the accumulator
type AnnotationAccumulator = {
  V: AutomaticAnnotationClusterData[];
  S: AutomaticAnnotationClusterData[];
};

type AutomaticAnnotationsChartsProps = {
  annotations: AutomaticAnnotationClusterData[];
};

// a function that takes a number and returns two random unique numbers between 0 and the number
function getRandomNumbers(max: number) {
  if (max < 2) return [0, 0];

  const maxNumber = max > 100 ? 100 : max;

  const first = Math.floor(Math.random() * maxNumber);
  let second = Math.floor(Math.random() * maxNumber);

  while (first === second) {
    second = Math.floor(Math.random() * maxNumber);
  }

  return [first, second];
}

export function ClusterChartList(props: AutomaticAnnotationsChartsProps) {
  const classes = useStyles();

  if (!props.annotations?.length) {
    return null;
  }

  const { V, S } = props.annotations.reduce<AnnotationAccumulator>(
    (acc, item) => {
      if (item.annotationValue === 'V') {
        acc.V.push(item);
      } else if (item.annotationValue === 'S') {
        acc.S.push(item);
      }

      return acc;
    },
    { V: [], S: [] },
  );

  return (
    <>
      <Container className={classes.root} component={Paper}>
        <Typography className={classes.title} variant="h6">
          Ventricular beats detected chart
        </Typography>
        <div className={classes.chartContainer}>
          {V.map((it) => {
            if (!it.isValid) {
              return null;
            }

            const [firstRandom, secondRandom] = getRandomNumbers(it.excerpts.length - 1);

            return (
              <React.Fragment key={it.clusterNumber}>
                <div className={classes.chart}>
                  <ExcerptMultipleChart
                    annotationValue={it.annotationValue}
                    clusterNumber={it.clusterNumber}
                    data={it.excerpts}
                  />
                </div>
                {it.excerpts.length > 3 && (
                  <>
                    <div className={classes.chart}>
                      <ExcerptMultipleChart
                        annotationValue={it.annotationValue}
                        clusterNumber={it.clusterNumber}
                        data={[it.excerpts[firstRandom]]}
                      />
                    </div>
                    <div className={classes.chart}>
                      <ExcerptMultipleChart
                        annotationValue={it.annotationValue}
                        clusterNumber={it.clusterNumber}
                        data={[it.excerpts[secondRandom]]}
                      />
                    </div>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </Container>

      <Container className={classes.root} component={Paper}>
        <Typography className={classes.title} variant="h6">
          Supraventricular beats detected chart
        </Typography>
        <div className={classes.chartContainer}>
          {S.map((it) => {
            if (!it.isValid) {
              return null;
            }

            const [firstRandom, secondRandom] = getRandomNumbers(it.excerpts.length - 1);

            return (
              <React.Fragment key={it.clusterNumber}>
                <div className={classes.chart}>
                  <ExcerptMultipleChart
                    annotationValue={it.annotationValue}
                    clusterNumber={it.clusterNumber}
                    data={it.excerpts}
                  />
                </div>
                {it.excerpts.length > 3 && (
                  <>
                    <div className={classes.chart}>
                      <ExcerptMultipleChart
                        annotationValue={it.annotationValue}
                        clusterNumber={it.clusterNumber}
                        data={[it.excerpts[firstRandom]]}
                      />
                    </div>
                    <div className={classes.chart}>
                      <ExcerptMultipleChart
                        annotationValue={it.annotationValue}
                        clusterNumber={it.clusterNumber}
                        data={[it.excerpts[secondRandom]]}
                      />
                    </div>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </>
  );
}
