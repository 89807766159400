import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { calculatePercentage } from './utils/calculatePercentage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 500,
  },
  cell: {
    border: 'none',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

function createData(name: string, count: string, percentages: string) {
  return { name, count, percentages };
}

function makeRows({
  avgHeartrate,
  beatCount,
  minHeartrate,
  maxHeartrate,
  supraventricularBeats,
  ventricularBeats,
  unrecognizedBeats,
  normalBeats,
  bradycardiaBeats,
  tachycardiaBeats,
  averageRatesBeats,
  qtcIntervalMin,
  qtcIntervalMax,
  qtcIntervalAverage,
  qtcIntervalsAbove05Percentage,
  pauseTotalCount,
  pauseLongestEpisodeSeconds,
}) {
  return [
    createData('Min/max heartrate', `${minHeartrate || 0}/${maxHeartrate || 0}`, ''),
    createData('Average heartrate', avgHeartrate || 0, ''),
    createData('Total beats', beatCount || 0, '100 %'),
    createData(
      'Bradycardia beats (<50)',
      '',
      calculatePercentage(bradycardiaBeats, averageRatesBeats),
    ),
    createData(
      'Tachycardia beats (>100)',
      '',
      calculatePercentage(tachycardiaBeats, averageRatesBeats),
    ),
    createData(
      'Supraventricular beats (S)',
      supraventricularBeats,
      calculatePercentage(supraventricularBeats, beatCount),
    ),
    createData(
      'Ventricular beats (V)',
      ventricularBeats,
      calculatePercentage(ventricularBeats, beatCount),
    ),
    createData('Unrecognized beats (U)', unrecognizedBeats, ''),
    createData('Normal beats (N)', normalBeats, ''),
    // create one row with min/mac qtc values
    createData(
      'QTc interval min/max (seconds)',
      `${qtcIntervalMin.toFixed(3)} / ${qtcIntervalMax.toFixed(3)}`,
      '',
    ),
    createData('QTc interval average (seconds)', qtcIntervalAverage.toFixed(3), ''),
    createData('QTc intervals above 0.5 seconds', '', `${qtcIntervalsAbove05Percentage}%`),
    createData('Pause total count', pauseTotalCount, ''),
    createData('Pause longest episode (seconds)', pauseLongestEpisodeSeconds, ''),
  ];
}

type SummeryProps = {
  avgHeartrate: number;
  beatCount: number;
  minHeartrate: number;
  maxHeartrate: number;
  supraventricularBeats: number;
  ventricularBeats: number;
  unrecognizedBeats: number;
  normalBeats: number;
  bradycardiaBeats: number;
  tachycardiaBeats: number;
  averageRatesBeats: number;
  qtcIntervalMin: number;
  qtcIntervalMax: number;
  qtcIntervalAverage: number;
  qtcIntervalsAbove05Percentage: number;
  pauseTotalCount: number;
  pauseLongestEpisodeSeconds: number;
};

export function Summary(props: { summary: SummeryProps }) {
  const classes = useStyles();

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Summary
      </Typography>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Count</TableCell>
            <TableCell align="right">Percentages</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {makeRows(props.summary).map((row) => (
            <TableRow key={row.name}>
              <TableCell className={classes.cell}>{row.name}</TableCell>
              <TableCell className={classes.cell} align="right">
                {row.count}
              </TableCell>
              <TableCell className={classes.cell} align="right">
                {row.percentages}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}
