import { gql, useMutation } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

import { GenericResponse } from 'types/schema.type';

import { getTime } from '../utils/getTime';
import { SequenceEpisodes } from '../utils/getSequenceEpisodes';
import { formatTimeDuration } from '../utils/formatTime';

export const TOGGLE_PAUSE_EPISODE_VALIDATION = gql`
  mutation TogglePauseEpisodeValidation($input: TogglePauseEpisodeValidationInput!) {
    togglePauseEpisodeValidation(input: $input) {
      error
      success
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 550,
  },
  cell: {
    border: 'none',
  },
  cellSummary: {
    borderBottom: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

type PauseEpisodesTableProps = {
  pauseEpisodes: SequenceEpisodes['pauseEpisodes'];
  analysisId?: string;
  edit?: boolean;
};

export function PauseEpisodesTable(props: PauseEpisodesTableProps) {
  const classes = useStyles();

  const { pauseEpisodes, edit, analysisId } = props;

  const [togglePauseEpisodeValidation] = useMutation<{
    togglePauseEpisodeValidation: GenericResponse;
  }>(TOGGLE_PAUSE_EPISODE_VALIDATION);

  const renderEmpty = () => (
    <TableBody>
      <TableRow>
        <TableCell className={classes.cell}>None detected</TableCell>
      </TableRow>
    </TableBody>
  );

  const handleTogglePauseEpisodeValidation = async (
    onsetSampleno?: number,
    analysisIdRef?: string,
  ) => {
    if (!analysisIdRef || !onsetSampleno) return;

    await togglePauseEpisodeValidation({
      variables: {
        input: {
          analysisId: analysisIdRef,
          onsetSampleno,
        },
      },
      update(cache) {
        cache.modify({
          id: cache.identify({ __typename: 'Analysis', id: analysisIdRef }),
          fields: {
            pauseEpisodes(existingEpisodes = []) {
              const result = existingEpisodes.map((episode) => {
                if (episode.endIndex === onsetSampleno) {
                  return {
                    ...episode,
                    isValid: !episode.isValid,
                  };
                }

                return episode;
              });

              return result;
            },
          },
        });
      },
    });
  };

  const episodesToShow = edit ? pauseEpisodes : pauseEpisodes?.filter((ep) => ep.isValid);

  const renderFull = () => (
    <>
      <TableHead>
        <TableRow>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell>Duration</TableCell>
          {edit && <TableCell>Action</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {episodesToShow?.map((ep) => {
          const startIndex = ep?.startIndex ?? 0;
          const endIndex = ep?.endIndex ?? 0;
          const duration = formatTimeDuration({
            durationInMilliseconds: (endIndex - startIndex) * 5,
            showSeconds: true,
          });

          return (
            <TableRow key={ep.endIndex}>
              <TableCell className={classes.cell}>
                {getTime(ep.recordingStartedAt ?? 0, ep?.startIndex ?? 0)}
              </TableCell>
              <TableCell className={classes.cell}>
                {getTime(ep.recordingStartedAt ?? 0, ep?.endIndex ?? 0)}
              </TableCell>
              <TableCell className={classes.cell}>{duration}</TableCell>
              {edit && (
                <IconButton edge="end" aria-label="" onClick={() => handleTogglePauseEpisodeValidation(ep.endIndex, analysisId)}>
                  {ep.isValid ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Pause episodes
      </Typography>
      <Table className={classes.table} size="small">
        {episodesToShow?.length ? renderFull() : renderEmpty()}
      </Table>
    </Container>
  );
}
