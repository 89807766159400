import { useMemo } from 'react';
import UPlotReact from 'uplot-react';
import format from 'date-fns/format';
import $ from 'jquery';
import uPlot from 'uplot';

import { buildIntervalIndicator } from 'utilities';
import { chunkArray } from '../utils/chunkArray';
import { formatDateTimeRange, formatTimeDuration } from '../utils/formatTime';
import { createBradycardiaSeries } from './bradycardiaUtils';
import { shouldRenderChartAnnotation } from '../utils/shouldRenderChartAnnotation';

type BradycardiaChartProps = {
  recordingStartedAt: number;
  startIndex: number;
  endIndex: number;
  smallestAverageIndex: number;
  signal: number[];
  rpeaks: number[];
  showTitle?: boolean;
};

const chartYAxisRange = [-2, 5];

export function BradycardiaChart({
  recordingStartedAt,
  startIndex,
  endIndex,
  signal,
  smallestAverageIndex,
  rpeaks,
  showTitle = true,
}: BradycardiaChartProps) {
  const dateRange = formatDateTimeRange({
    fromMiliseconds: recordingStartedAt + (startIndex / 200) * 1000,
    toMiliseconds: recordingStartedAt + (endIndex / 200) * 1000,
    showSeconds: true,
  });
  const duration = formatTimeDuration({
    durationInMilliseconds: (endIndex - startIndex) * 5,
    showSeconds: true,
  });

  const rpeakIndexesMap = rpeaks.reduce((acc, rpeak) => {
    acc[rpeak] = true;

    return acc;
  }, {} as Record<number, boolean>);

  const xAxis = useMemo(
    () => Array.from({ length: signal.length }, (_, i) => startIndex - 1000 + i),
    [signal.length, startIndex],
  );

  const rpeaksIndexes = useMemo(
    () => xAxis.map((x) => (rpeakIndexesMap[x] ? x : null)),
    [xAxis, rpeakIndexesMap],
  );

  const bradycardiaTopSeries = useMemo(
    () => createBradycardiaSeries(xAxis, startIndex, endIndex, chartYAxisRange[1]),
    [xAxis, startIndex, endIndex],
  );
  const bradycardiaBottomSeries = useMemo(
    () => createBradycardiaSeries(xAxis, startIndex, endIndex, chartYAxisRange[0]),
    [xAxis, startIndex, endIndex],
  );

  const signalChunks = useMemo(() => chunkArray(signal), [signal]);
  const xAxisChunks = useMemo(() => chunkArray(xAxis), [xAxis]);
  const rpeaksChunks = useMemo(() => chunkArray(rpeaksIndexes), [rpeaksIndexes]);

  const bradycardiaTopChunks = useMemo(
    () => chunkArray(bradycardiaTopSeries),
    [bradycardiaTopSeries],
  );
  const bradycardiaBottomChunks = useMemo(
    () => chunkArray(bradycardiaBottomSeries),
    [bradycardiaBottomSeries],
  );

  const smallestChunkIndex = xAxisChunks.findIndex((chunk) => {
    const firsChunkValue = chunk[0];
    const lastChunkValue = chunk[chunk.length - 1];

    return smallestAverageIndex >= firsChunkValue && smallestAverageIndex <= lastChunkValue;
  });

  let firstRenderableChunkFound = false;

  return (
    <>
      {signalChunks.map((chunk, index) => {
        /**
         * Skip rendering based on the position of the smallest chunk:
         * - For the first chunk case (smallestChunkIndex === 0), it skips rendering chunks beyond the first three.
         * - For the last chunk case (smallestChunkIndex === signalChunks.length - 1), it skips rendering chunks until the last three.
         * - For the middle chunk case, it only renders the chunk before, the smallest chunk, and the chunk after.
         */
        const shouldRender =
          (smallestChunkIndex === 0 && index < 3) ||
          (smallestChunkIndex === signalChunks.length - 1 && index >= signalChunks.length - 3) ||
          index === smallestChunkIndex ||
          index === smallestChunkIndex - 1 ||
          index === smallestChunkIndex + 1 ||
          (index === signalChunks.length - 1 && xAxisChunks[index].includes(endIndex));

        if (!shouldRender) return null;

        const isThisFirstRenderableChunk = !firstRenderableChunkFound;

        if (isThisFirstRenderableChunk) {
          firstRenderableChunkFound = true;
        }

        // return null if endIndex is not in the last chunk
        if (index === signalChunks.length - 1 && !xAxisChunks[index].includes(endIndex)) {
          return null;
        }

        const options = {
          title: isThisFirstRenderableChunk && showTitle ? `Bradycardia episode ${dateRange} (${duration})` : '',
          width: 700, // A4 format - 210mm == 800px
          height: 220,
          series: [
            {
              label: 'Time',
              value: (_, rawValue) =>
                format(recordingStartedAt + (rawValue / 200) * 1000, 'yyy-MM-dd H:mm:ss'),
            },
            { label: 'Voltage, mV', stroke: 'red' },
            { fill: 'rgba(128, 128, 128, 0.2)' },
            { fill: 'rgba(128, 128, 128, 0.2)' },
          ],
          axes: [
            {
              values: (_, splits) =>
                splits.map((idx) => format(recordingStartedAt + (idx / 200) * 1000, 'H:mm:ss')),
            },
            { label: 'Voltage, mV' },
          ],
          hooks: {
            drawAxes: [
              (u: uPlot) => {
                let previousSampleIndex;

                rpeaksChunks[index].forEach((currentSampleValue, idx, arr) => {
                  if (currentSampleValue === null) return;

                  if (!previousSampleIndex) {
                    previousSampleIndex = idx;

                    return;
                  }

                  // currentSampleIndex is the index of the sample in the chunk
                  const currentAnnotationVoltage = u.data[1][idx] as number;
                  const currentSamplePosX = Math.round(
                    u.valToPos(currentSampleValue as number, 'x', false),
                  );
                  const currentSamplePosY = Math.round(
                    u.valToPos(currentAnnotationVoltage, 'y', false),
                  );

                  // previousSampleIndex is the index of the sample in the chunk
                  const previousAnnotationVoltage = u.data[1][previousSampleIndex] as number;
                  const previousSampleValue = arr[previousSampleIndex] as number;
                  const previousSamplePosX = Math.round(
                    u.valToPos(previousSampleValue, 'x', false),
                  );
                  const previousSamplePosY = Math.round(
                    u.valToPos(previousAnnotationVoltage, 'y', false),
                  );

                  const shouldRenderAnnotation = shouldRenderChartAnnotation(
                    previousAnnotationVoltage,
                    currentAnnotationVoltage,
                  );

                  if (shouldRenderAnnotation) {
                    $(u.root.querySelector('.u-over')).append(
                      buildIntervalIndicator({
                        fromX: previousSamplePosX,
                        fromY: previousSamplePosY,
                        toX: currentSamplePosX,
                        toY: currentSamplePosY,
                        content: `${(currentSampleValue - previousSampleValue) / 200}s`,
                      }),
                    );
                  }

                  previousSampleIndex = idx;
                });
              },
            ],
          },
          plugins: [],
          legend: { show: false },
          scales: { x: { time: true }, y: { range: chartYAxisRange } },
        };

        return (
          <div key={`${index + 1}`}>
            <UPlotReact
              options={options as any}
              data={[
                xAxisChunks[index] as number[],
                chunk,
                bradycardiaTopChunks[index],
                bradycardiaBottomChunks[index],
              ]}
            />
          </div>
        );
      })}
    </>
  );
}
