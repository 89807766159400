import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, redirect } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { AccountCircle } from '@material-ui/icons';
import { Omit } from '@material-ui/types';
import { useAuth0 } from '@auth0/auth0-react';

interface ListItemLinkProps {
  text: string;
  onClick: () => void;
  to: string;
}

function MenuItemLink(props: ListItemLinkProps) {
  const { text, onClick, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <MenuItem component={renderLink} button onClick={onClick}>
      {text}
    </MenuItem>
  );
}

export function ButtonMenuLayout() {
  const { user, logout } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();

    localStorage.removeItem('sharedToken');
    localStorage.removeItem('loginToken');

    if (user) {
      // If user is Auth0 user, use Auth0 logout
      logout({ returnTo: window.location.origin });
    } else {
      // User is impersonated and using shared token, redirect to login
      redirect('/');
      window.location.reload();
    }
  };

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
      >
        {user && <MenuItemLink to="/account" text="My account" onClick={handleClose} />}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </div>
  );
}
