import { formatDateTimeRange, formatTimeDuration } from './formatTime';

export function getSequenceTitle({
  from,
  to,
  seconds,
}: {
  from: number;
  to: number;
  seconds: number;
}) {
  const dateRange = formatDateTimeRange({
    fromMiliseconds: from,
    toMiliseconds: to,
    showSeconds: false,
    detailed: true,
  });

  // Get the human-readable duration with detailed output
  const durationHumanReadable = formatTimeDuration({
    durationInMilliseconds: seconds * 1000,
    detailed: true,
  });

  const title = `ECG Report for ${durationHumanReadable}`;
  const subtitle = `Examination Period: ${dateRange}`;

  return {
    title,
    subtitle,
  };
}
