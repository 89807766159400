export const calculateBMIWithCategory = ({ sex, age, weight, height }) => {
  if (!sex || !age || !weight || !height) {
    return 'N/A';
  }

  // Convert height from centimeters to meters
  const heightInMeters = height / 100;

  // Calculate BMI
  const bmi = weight / (heightInMeters * heightInMeters);

  // Determine the recommended BMI range and adjustment based on age and sex
  let normBMI = 0;
  let adjustment = 0;

  if (sex.toLowerCase() === 'female') {
    if (age >= 19 && age <= 24) {
      normBMI = 19.5;
      adjustment = -5.5;
    } else if (age >= 25 && age <= 34) {
      normBMI = 23.2;
      adjustment = -1.8;
    } else if (age >= 35 && age <= 44) {
      normBMI = 23.4;
      adjustment = -1.6;
    } else if (age >= 45 && age <= 54) {
      normBMI = 25.2;
      adjustment = +0.2;
    } else if (age >= 55) {
      normBMI = 27.3;
      adjustment = +2.3;
    }
  } else if (sex.toLowerCase() === 'male') {
    if (age >= 19 && age <= 24) {
      normBMI = 21.4;
      adjustment = -3.6;
    } else if (age >= 25 && age <= 34) {
      normBMI = 21.6;
      adjustment = -3.4;
    } else if (age >= 35 && age <= 44) {
      normBMI = 22.9;
      adjustment = -2.1;
    } else if (age >= 45 && age <= 54) {
      normBMI = 25.8;
      adjustment = +0.8;
    } else if (age >= 55) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      normBMI = 26.6;
      adjustment = +1.6;
    }
  } else {
    return 'N/A';
  }

  // Adjusted BMI for categorization
  const adjustedBMI = bmi + adjustment;

  // Determine the BMI category
  let category = '';

  if (adjustedBMI < 16.0) {
    category = 'Severely underweight';
  } else if (adjustedBMI >= 16.0 && adjustedBMI < 16.9) {
    category = 'Moderately underweight';
  } else if (adjustedBMI >= 17.0 && adjustedBMI < 18.4) {
    category = 'Mildly underweight';
  } else if (adjustedBMI >= 18.5 && adjustedBMI < 24.9) {
    category = 'Normal (healthy weight)';
  } else if (adjustedBMI >= 25.0 && adjustedBMI < 29.9) {
    category = 'Overweight';
  } else if (adjustedBMI >= 30.0 && adjustedBMI < 34.9) {
    category = 'Obese Class I (Moderate)';
  } else if (adjustedBMI >= 35.0 && adjustedBMI < 39.9) {
    category = 'Obese Class II (Severe)';
  } else if (adjustedBMI >= 40.0) {
    category = 'Obese Class III (Very severe or morbidly obese)';
  }

  return `${adjustedBMI.toFixed(2)} - ${category}`;
};
