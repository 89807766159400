import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RecordingJson } from 'types/schema.type';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { AFibChart } from './AFibChart';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  chart: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type Episode = {
  recordingStartedAt?: number;
  startIndex: number;
  endIndex: number;
  isValid: boolean;
  excerpt: RecordingJson;
};

type EpisodeChartListProps = {
  episodes: Episode[] | undefined;
  rpeaks: number[];
  isLoading: boolean;
};

export function AFibChartList(props: EpisodeChartListProps) {
  const classes = useStyles();

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Atrial Fibrillation episode charts detected
      </Typography>
      {props.isLoading ? (
        <LoadingSpinner />
      ) : (
        props.episodes
        ?.filter((ep) => ep.isValid && ep?.excerpt?.ch1.length > 0)
        .map((episode) => (
          <div className={classes.chart} key={episode.startIndex}>
            <AFibChart
              recordingStartedAt={episode.recordingStartedAt ?? 0}
              startIndex={episode.startIndex}
              endIndex={episode.endIndex}
              signal={episode.excerpt.ch1}
              rpeaks={props.rpeaks}
            />
          </div>
        ))
      )}
    </Container>
  );
}
