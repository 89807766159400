import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 800,
      flexGrow: 1,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const warnings: { id: number; type: string; text: string }[] = [
  {
    type: 'Warning',
    text: 'This is not a diagnosis. Do not self-diagnose, consult your doctor for diagnosis.',
  },
  { type: 'Warning', text: 'Only use Zive AI in accordance with its intended use.' },
  {
    type: 'Warning',
    text: 'Zive AI contains personalized data. It must only be used by the same individual only. Do not share your Zive ECG device with other persons during monitoring.',
  },
  {
    type: 'Warning',
    text: 'The results provided by Zive AI are not to be used as the basis for beginning of changing therapeutic measures without an independent medical examination.',
  },
  {
    type: 'Warning',
    text: 'ECG readings by Zive AI are not able to detect all changes indicative of cardiac disease! If you notice changes to your state of health, contact your physician instantly. In case of prevailing symptoms in Zive AI reports, contact your doctor instantly, even if the results provided by Zive AI show no or minor irregularities.',
  },
  {
    type: 'Warning',
    text: 'Do not attempt to make a diagnosis on the basis of the results and analysis provided by Zive AI. Always check with your physician. Self-diagnosis may cause a deterioration of your state of health or medical condition.',
  },
  {
    type: 'Warning',
    text: 'Results provided by Zive AI reflect the time interval the recording was taken at. Your state of health may change rapidly. In case you notice a change in your state of health, contact a physician.',
  },
  {
    type: 'Warning',
    text: 'Note that a physician may only receive your ECG recordings and your attached comments if you are actively linked to the physician through the Zive AI database. Transmission of ECG data to the linked physician is not guaranteed. In addition, when your ECG data is received by your physician, this does not guarantee that he or she will look at the data or establish a diagnosis. It is recommended that you contact the physician you are linked to directly. This is especially important in the case of an emergency.',
  },
  {
    type: 'Warning',
    text: 'Before performing a reading with Zive AI sit calmly for a minimum of 3 minutes. Ensure that your posture is relaxed. Being seated with a straight back is ideal. Do not cough or speak during a reading. Body movements may cause interference influencing the evaluation.',
  },
  {
    type: 'Warning',
    text: 'Do not perform readings when subjected to vibrations, e.g. in a moving vehicle, train or plane.',
  },
  {
    type: 'Warning',
    text: 'Do not perform ECG readings if the attachment points of the electrodes on your skin are wet, e.g. due to sweat or after bathing.',
  },
  {
    type: 'Warning',
    text: 'Proper functioning of the disposable electrodes is influenced by large amounts of body hair, cream or oily skin. Regularly remove hair from your skin at the attachment points of the electrodes and ensure that your skin is free of cream and fat. Do not clean your skin with alcohol.',
  },
  {
    type: 'Warning',
    text: 'Do not record ECG with incorrectly positioned or transposed electrodes, as a correct evaluation of subsequent ECGs is not possible.',
  },
  {
    type: 'Warning',
    text: 'Only use the Zive ECG device as described in this user manual and provided by Zive UAB.',
  },
  {
    type: 'Warning',
    text: 'Do not use Zive ECG when using an external defibrillator (Zive ECG is not defibrillator proof).',
  },
  {
    type: 'Warning',
    text: 'During use of Zive ECG, do not connect any other device or computer via an adapter to your smartphone and/or to the headset jack of your smartphone.',
  },
  {
    type: 'Warning',
    text: 'Do not perform readings in places exposing Zive ECG to high electromagnetic radiation (e.g. TV sets, monitors, dishwashers, dimmers).',
  },
  {
    type: 'Warning',
    text: 'Do not expose Zive ECG to any electrostatics. Discharge any electrostatics out of your body before touching Zive ECG.',
  },
  { type: 'Warning', text: 'Zive AI is not suitable for use with pacemakers.' },
  {
    type: 'Attention',
    text: 'Ensure that date and time in your smartphone are set correctly.',
  },
  {
    type: 'Attention',
    text: 'Contact our customer support if you are unable to perform a firmware update.',
  },
].map((it, index) => ({ id: index + 1, ...it }));

export function Warnings() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid spacing={3} container>
        <Grid xs={12} item>
          {warnings.map((it) => (
            <Typography key={it.id} paragraph>
              <b>{it.type}</b> {it.text}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
